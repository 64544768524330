.header {
  position: relative;
  width: 100vw;
  overflow: hidden;
  background: $color-secondary;

  &__img {
    position: fixed;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.8;
  }

  &__logo {
    height: 12rem;
    width: auto;
  }

  .section-brand {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .waves {
    width: 100vw;
    position: absolute;
    bottom: -5rem;
    transform: scaleY(0.7);

    & > svg {
      width: 100%;
    }
  }

  &--home {
    height: 100vh;
  }
}
