.footer {
  ::selection {
    background-color: $color-secondary;
    color: $color-primary;
  }
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $color-primary;
  color: $color-font-light;
  & h2 {
    color: $color-font-light;
  }
  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    text-align: center;
    p:first-child {
      text-transform: uppercase;
    }

    @include respond(phone) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 4rem;
    }
  }
  &__copyright {
    font-size: 1.4rem;
  }
  &__link {
    text-decoration: none;
    color: $color-font-light;
    transition: all 0.3s;
    font-size: 1.4rem;

    &:hover,
    &:active,
    &:focus {
      color: $color-font-light-active;
    }
  }

  @include respond(phone) {
    p {
      margin-bottom: 0rem !important;
    }
  }
}
