.main {
  position: relative;
  background-color: $color-secondary;

  .section-presentation {
    @include allHeight;

    @include respond(tab-land) {
      min-height: 60vh;
    }
    .l-container-presentation {
      display: flex;
      align-items: center;
      justify-content: center;

      @include respond(phone) {
        flex-direction: column;
      }

      &__div {
        width: 50%;
        height: auto;
      }
      &__img {
        width: 70%;
        object-fit: cover;
        display: block;
        margin: 0 auto;
        border-radius: $border-radious;

        @include respond(phone) {
          width: 100%;
        }
      }

      .paragraph {
        width: 50%;
        font-style: italic;

        @include respond(tab-land) {
          padding-right: 8rem;
        }

        @include respond(phone) {
          width: 80%;
          padding-right: 0;
        }
      }
    }
  }

  .section-reviews {
    @include allHeight;

    @include respond(tab-land) {
      min-height: 60vh;
    }

    background: url('../img/filo_1x.jpg') no-repeat center fixed;
    background-size: cover;
    background-color: #cccccc;
    background-attachment: scroll;
    position: relative;
    overflow: hidden;

    @media (min-resolution: 192dpi) {
      background: url('../img/filo_2x.jpg') no-repeat center fixed;
      background-size: cover;
      background-color: #cccccc;
      background-attachment: scroll;
    }
  }

  .section-slideshow {
    @include allHeight;
    @include respond(phone) {
      padding: 5rem 0;
    }

    &__heading {
      display: none;
    }
  }
}
