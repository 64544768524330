.c-consent {
  position: fixed;
  width: 70%;
  max-width: 100rem;
  top: 0;
  left: 0;
  right: 0;
  padding: 4rem 6rem;
  background-color: $color-primary;
  color: $color-font-light;
  transition: all 1s ease;
  z-index: 5;
  border-radius: $border-radious;
  margin: 2rem auto;

  @include respond(tab-port) {
    width: initial;
    margin: 3rem;
    padding: 4rem;
  }

  &--hidden {
    opacity: 0;
    transform: translateY(-100%);
  }

  &__link {
    color: $color-font-light;
    text-decoration: none;
    font-style: italic;
    transition: 0.2s;

    &:hover,
    &:focus {
      color: $color-font-light-active;
      text-decoration: none;
      cursor: pointer;
    }
  }

  &__row {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
  }

  &__close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: $color-secondary;
    font-size: 35px;
    font-weight: bold;
    transition: 0.2s;
    z-index: 2; /* Sit on top */

    &:hover,
    &:focus {
      color: $color-font-light-active;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
