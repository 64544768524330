@mixin centerVerHor {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin allHeight {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// MEDIA QUERIES MANAGER
/*
0 - 600px:        Phone
600 - 900px:      Tablet Portrait
900 - 1200px:     Tablet landscape
[1200 - 1800px:]    Desktop - Where our normal styles apply
1800px + :      Big Desktop 

$breakpoint argument choices:
- phone 
- tab-port
- tab-land
- desktop
- big-desktop

Order: Base - typography - general layout - page layout - components

1em = 16px
*/
@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      @content;
    } // 600px
  }
  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {
      @content;
    } // 900px
  }
  @if $breakpoint == tab-land {
    @media (max-width: 75em) {
      @content;
    } // 1200px
  }
  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      @content;
    } // 1800px
  }
}
