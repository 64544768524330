.c-card {
  display: flex;
  align-items: center;
  margin-bottom: 12rem;

  &:nth-child(2n + 1) {
    flex-direction: row-reverse;
  }

  @include respond(phone) {
    flex-direction: column;
    margin-bottom: 3rem;

    &:nth-child(2n + 1) {
      flex-direction: column;
    }
  }

  &__container {
    width: 55%;
    height: 40rem;

    @include respond(phone) {
      width: 100%;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: $border-radious;
  }
  &__text {
    width: 45%;
    margin: 5rem 0 5rem 5rem;

    @include respond(phone) {
      width: 100%;
      margin: 5rem 3rem;
    }
  }

  &:nth-child(2n + 1) > &__text {
    margin: 5rem 5rem 5rem 0;
  }

  @include respond(phone) {
    &:nth-child(2n + 1) > &__text {
      margin: 5rem 3rem;
    }
  }
}
