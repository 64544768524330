// CENTER
.u-center-text {
  text-align: center !important;
}

// MARGINS
// Bottom
.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}
.u-margin-bottom-medium {
  margin-bottom: 4rem !important;
}
.u-margin-bottom-big {
  margin-bottom: 8rem !important;
}

@include respond(phone) {
  .u-margin-bottom-big {
    margin-bottom: 2rem !important;
  }
}

.u-margin-bottom-huge {
  margin-bottom: 12rem !important;
}

// Top
.u-margin-top-small {
  margin-top: 1.5rem !important;
}

.u-margin-top-medium {
  margin-top: 4rem !important;
}

.u-margin-top-big {
  margin-top: 8rem !important;
}
.u-margin-top-huge {
  margin-top: 12rem !important;
}

// Top and Bottom
.u-margins-small {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.u-margins-medium {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.u-margins-big {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.u-margins-huge {
  margin-top: 15rem !important;
  margin-bottom: 15rem !important;
}

// WIDTHS
.u-max-width {
  max-width: 110rem;
  margin: 0 auto;
}

@include respond(tab-land) {
  .u-max-width {
    max-width: 90%;
    margin: 0 auto;
  }
}
