body {
  font-family: $p-font-family, serif;
  font-size: 1.6rem;
  line-height: 1.7;
  color: $color-font-dark;
}

.heading-primary {
  font-family: $h1-font-family, serif;
  font-weight: 300;
  font-size: $h1-font-size;
  color: $color-font-dark;

  @include respond(tab-port) {
    font-size: 5rem;
    line-height: normal;
  }
}

.heading-secondary {
  font-family: $h2-font-family, serif;
  font-size: $h2-font-size;
  font-weight: 500;
  color: $color-font-dark;
  letter-spacing: 2px;
  margin-bottom: 1.5rem;
}

.heading-tertiary {
  font-family: $h3-font-family, serif;
  font-size: $h3-font-size;
  font-weight: 500;
}

.paragraph {
  font-family: $p-font-family, serif;
  font-size: $p-font-size;
  font-weight: 200;
  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}
