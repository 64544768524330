.c-socials {
  display: flex;
  justify-content: space-between;
  width: 25%;

  @include respond(phone) {
    width: 50%;
  }

  &__item {
    height: 4rem;
    width: 4rem;
    transition: all 0.3s;
    &:hover,
    &:active {
      transform: scale(1.1);
    }
  }
  &__img {
    width: 5rem;
    height: auto;
  }
}
