.c-testimonials {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 5rem;
  margin: 5rem;

  @include respond(tab-port) {
    height: 80%;
    grid-template-columns: 1fr;
    grid-gap: 5rem;
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(#ffffff, 0.93);
    border-radius: $border-radious;
    padding: 4rem;

    @include respond(tab-port) {
      // height: 100%;
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
  }
  &__text {
    font-size: 1.4rem;
    font-style: italic;

    @include respond(tab-port) {
      margin-bottom: 0 !important;
    }
  }
  &__user {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include respond(tab-port) {
      margin-bottom: 2rem;
    }

    & .paragraph {
      font-size: 1.4rem;
      font-style: italic;
    }
  }
  &__photo {
    width: 8rem;
    border-radius: 50%;
  }
  // &__name {
  //   margin-left: 2rem;
  // }
}
