.c-scroll-arrow {
  display: inline-block;
  width: 10rem;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
  &__text {
    text-align: center;
    margin-bottom: 2rem;
    border-bottom: 0.2rem solid $color-font-dark;
    color: $color-font-dark;
  }
}
