// COLORS
$color-primary: #879c8c;
$color-secondary: #fff;
// $color-tertiary: #947357;
$color-tertiary: #6e7c71;

$color-font-dark: #242424;
$color-font-light-active: #505050;

$color-font-light: #fff;
$color-font-light-active: rgb(218, 218, 218);

// FONTS
$h1-font-family: 'Lovers Quarrel', cursive;
$h1-font-size: 7rem;

$h2-font-family: 'Parisienne', cursive;
$h2-font-size: 2.8rem;

$h3-font-family: 'Parisienne', cursive;
$h3-font-size: 2.2rem;

$p-font-family: 'Taviraj', serif;
$p-font-size: 1.6rem;

$btn-font-size: 1.4rem;

// IMAGES
$border-radious: 0.5rem;
