.c-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, minmax(2rem, 1fr));
  grid-gap: 5rem;

  @include respond(phone) {
    grid-template-columns: 1fr;
  }

  &__label {
    display: flex;
    flex-direction: column;

    &--text-area {
      grid-column: 1 / span 2;
    }
  }
  &__input {
    min-height: 3rem;
    border: none;
    border-bottom: 1px solid #ddd;
    transition: all 0.3s;

    &--text-area {
      width: 100%;
      min-height: 3rem;
    }

    &:hover,
    &:active,
    &:focus {
      border-bottom: 1px solid #aaa;
      outline: none;
    }
  }

  &__btn {
    width: 20rem;
    height: 5rem;
    border: none;
    border-radius: $border-radious;
    background-color: $color-primary;
    color: $color-font-light;
    font-size: 1.6rem;
    font-family: $p-font-family, sans-serif;
    transition: all 0.2s;

    &:hover,
    &:active,
    &:focus {
      transform: scale(1.02);
    }
  }
}
