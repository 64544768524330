.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-auto-rows: 20rem;
  grid-gap: 3rem;

  @include respond(phone) {
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  }
  &__item {
    cursor: pointer;
    position: relative;
    padding: 3rem;
    background-color: $color-primary;
    border-radius: 3px;
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 3px;
  }

  &__number {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    color: $color-font-light;
  }
}
