.nav {
  position: fixed;
  width: 33rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: space-between;
  background-color: $color-primary;
  opacity: 0.9;
  transform: translateX(-100%);
  z-index: 100;

  .l-socials {
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;

    .menu-socials {
      &__item {
        width: 2.5rem;
        height: 2.5rem;
        margin: 0 1rem;
      }
    }
  }

  &__list {
    padding-left: 8rem;
    margin-top: 8rem;
    list-style: none;
  }

  &__item {
    font-size: 1.8rem;
    padding-bottom: 2rem;

    &:hover,
    &:active {
      background-image: url('../img/underline.png');
      background-size: 35%;
      background-repeat: no-repeat;
      background-position: 0 1.8rem;
    }
  }

  &__link {
    text-decoration: none;
    &:link,
    &:visited {
      color: $color-font-light;
    }
  }

  // HOVER AREA
  &__hover {
    position: absolute;
    width: 6rem;
    height: 100vh;
    top: 50%;
    right: 0;
    transform: translate(100%, -50%);
  }

  // ICON
  &__btn {
    @include centerVerHor;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
  }

  &__burger {
    width: 3rem;
    height: 0.2rem;
    background-color: $color-font-dark;
    border-radius: 0.5rem;
    transition: all 0.5s ease-in-out;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 3rem;
      height: 0.2rem;
      background-color: $color-font-dark;
      border-radius: 0.5rem;
      transition: all 0.5s ease-in-out;
    }

    &::before {
      transform: translateY(-1rem);
    }

    &::after {
      transform: translateY(1rem);
    }
  }

  // X
  &__close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 4rem;
    height: 4rem;
    display: block;
    cursor: pointer;
    z-index: 10;
  }

  // Hide btn on pc and higher
  @media (min-width: 75em) {
    &__close {
      display: none;
    }
  } // 1200px

  &__cross {
    &:before,
    &:after {
      position: absolute;
      left: 1.6rem;
      top: -0.2rem;
      content: ' ';
      height: 3.8rem;
      width: 0.1rem;
      background-color: $color-font-light;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }

  // SUBMENU
  &__submenu {
    list-style: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }

  &__sub-item {
    & .nav__link {
      text-decoration: none;
      display: block;
      padding-left: 2rem;
      transition: all 0.15s;

      &:hover,
      &:active {
        color: $color-font-light-active;
      }
    }

    &:first-child {
      margin-top: 2rem;
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}
