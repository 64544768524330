.c-slideshow {
  width: 80%;
  display: grid;
  grid-template-columns: 8rem auto 8rem;
  grid-template-rows: 70vh;

  @include respond(phone) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 10rem;
    grid-gap: 5rem;
    margin: 0 5rem;
  }

  &__btn {
    background-color: transparent;
    border: none;
    transition: all 0.2s;
    cursor: pointer;

    @include respond(phone) {
      grid-row: 1 / 2;
    }

    &#j-rightArrow > svg {
      transform: rotate(180deg);
    }

    &:focus {
      outline: $color-primary;
    }
    &:hover,
    &:active {
      transform: scale(1.1);
      border: none;
    }
  }

  &__svg {
    width: 30%;
    fill: $color-primary;

    @include respond(phone) {
      height: 100%;
    }
  }
  &__grid {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 2rem;

    @include respond(tab-port) {
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: repeat(2, 1fr);
    }

    @include respond(phone) {
      grid-row: 2/3;
      grid-column: 1/3;
      grid-template-rows: repeat(4, 1fr);
      grid-template-columns: 1fr;
    }
  }

  &__group {
    position: relative;
    min-height: 0; // Otherwise cell fits its content and overflow
    overflow: hidden;
    border-radius: $border-radious;
    &--1 {
      grid-row: 1 / 1;
      grid-column: 1 / span 2;

      @include respond(tab-port) {
        grid-column: 1 / 2;
      }

      @include respond(phone) {
        grid-column: 1 / 1;
      }
    }
    &--2 {
      grid-row: 1 / 1;
      grid-column: 3 / span 3;

      @include respond(tab-port) {
        grid-column: 2 / 3;
      }

      @include respond(phone) {
        grid-row: 2 / 2;
        grid-column: 1 / span 1;
      }
    }
    &--3 {
      grid-row: 2 / 2;
      grid-column: 1 / span 3;

      @include respond(tab-port) {
        grid-column: 1 / 2;
      }

      @include respond(phone) {
        grid-row: 3 / 3;
        grid-column: 1 / span 1;
      }
    }
    &--4 {
      grid-row: 2 / 2;
      grid-column: 4 / span 2;

      @include respond(tab-port) {
        grid-column: 2 / 3;
      }

      @include respond(phone) {
        grid-row: 4 / 4;
        grid-column: 1 / span 1;
      }
    }

    @include respond(phone) {
      height: 25rem;
    }
  }

  &__img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transform: translateX(101%);
  }
}
