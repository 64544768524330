.c-btn {
  display: inline-block;
  font-family: $p-font-family;
  font-size: $btn-font-size;
  padding: 0.5rem 2.5rem;
  cursor: pointer;
  border: 2px solid $color-secondary;
  background-size: 230%;
  transition: all 0.2s;
  border-radius: 4rem;
  background-color: $color-secondary;
  color: $color-font-dark;

  &:link,
  &:visited {
    text-decoration: none;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $color-tertiary;
    color: $color-font-light;
  }

  &--dark {
    background-color: $color-tertiary;
    color: $color-font-light;
    &:hover,
    &:active,
    &:focus {
      background-color: $color-secondary;
      color: $color-font-dark;
    }
  }
}
